<template>
    <div class="bg-primary-theme">
        <div class="bg-image-full entry-hero"
            :style="{ backgroundImage: 'url(' + require('/public/img/landscape.jpg') + ')' }">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 section-height d-flex align-items-center">
                        <h1 class="display-4" v-html="$t('paginas.homepage.section1.slug')"></h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-theme">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 offset-md-1 py-0 mb-5 py-md-5 order-2 order-md-1">
                        <h2 class="mb-3 display-5" v-html="$t('paginas.homepage.section2.title')"></h2>
                        <div id="three-steps">
                            <div>
                                <div class="stap-icon">
                                    <font-awesome-icon :icon="['fat', 'earth-europe']" />
                                </div>
                                <div>
                                    <h6>{{ $t("paginas.homepage.section2.stappen.stap1.title") }}</h6>
                                    <p>{{ $t("paginas.homepage.section2.stappen.stap1.description") }}</p>
                                </div>
                            </div>
                            <div>
                                <div class="stap-icon">
                                    <font-awesome-icon :icon="['fat', 'signature']" />
                                </div>
                                <div>
                                    <h6>{{ $t("paginas.homepage.section2.stappen.stap2.title") }}</h6>
                                    <p>{{ $t("paginas.homepage.section2.stappen.stap2.description") }}</p>
                                </div>
                            </div>
                            <div>
                                <div class="stap-icon">
                                    <font-awesome-icon :icon="['fat', 'trophy']" />
                                </div>
                                <div>
                                    <h6>{{ $t("paginas.homepage.section2.stappen.stap3.title") }}</h6>
                                    <p>{{ $t("paginas.homepage.section2.stappen.stap3.description") }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 offset-md-1 order-1 order-md-2">
                        <div id='donatie-kaart' class="card p-4 p-md-5">
                            <h2 class="mb-3">{{ $t("paginas.homepage.section1.title") }}</h2>
                            <donatie-stappen />
                        </div>
                        <div class="mt-3 mb-5">
                            <paid-donations />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DonatieStappen from "@/components/DonatieStappen.vue";
import PaidDonations from "@/components/PaidDonations.vue";

export default {
    name: "HomeView",
    components: {
        DonatieStappen,
        PaidDonations
    },

}
</script>

<style scoped>
h1.display-4 {
    font-weight: 900;
    color: #fcc323;
    font-size: 11rem;
    line-height: 10rem;
    text-align: left;
    font-family: 'Source Sans 3', sans-serif;
    text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.23);
}


#donatie-kaart {
    margin: -200px -12px 0;
}


@media screen and (max-width: 1200px) {
    h1.display-4 {
        font-size: 9rem;
        line-height: 8rem;
    }

}

@media screen and (max-width: 768px) {
    h1.display-4 {
        font-size: 4rem;
        line-height: 3.4rem;
    }

    #donatie-kaart {
        margin-top: -100px;
    }
}

#three-steps>div {
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

#three-steps>div h6 {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0;
}

#three-steps>div p {
    font-size: 0.9rem;
    margin-bottom: 0;
    color: var(--secondary-dark);
}

.stap-icon {
    background: var(--secondary);
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}
</style>