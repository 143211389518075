<template>
    <div>
        <div
            v-for="(donation, k) in this.$store.state.donations.paidDonations"
            :key="k"
            class="card donation-item mb-2 w-100"
        >   
            <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0"><strong>{{ donation.donated_by_name }}</strong></h6>
                <h6 class="mb-0"> {{ this.round(donation.amount) }} m²</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaidDonations",
    mounted(){
        this.$store.dispatch('donations/fetchPaidDonations');
    },
    methods: {
        round(number){
            return Math.round(number * 100) / 100;
        }
    }
}
</script>

<style scoped>
    .donation-item{
        padding: 12px;
        width: 100%;
    }
</style>