<template>
    <div id="navbar-wrapper" :class="navbarClass">
        <!-- navbar should be full with without background on top of page, but become smaller on scroll and have a white background  -->
        <div id='navbar' class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="container">
                    <a class="navbar-brand" href="https://www.minderzooi.nl/" id="centernav">
                        <h5 class='logo-text' v-html="$t('algemeen.logo')"></h5>
                    </a>
                    <div class="collapse navbar-collapse" id="colnav">
                        <ul class="navbar-nav">
                        </ul>
                        <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
                            <li class="nav-item ">
                                <a class="nav-link" href="https://www.minderzooi.nl/">
                                    Menu
                                    <font-awesome-icon class='menu-gripper' :icon="['far', 'grip-lines']" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavigatieBalk",
    data() {
        return {
            scroll: 0
        }
    },
    computed: {
        navbarClass() {
            return {
                'detached': this.scroll > 20,
                'nav-top-full': this.scroll === 0
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scroll = window.scrollY;
        }
    }
}
</script>

<style scoped>
#navbar-wrapper {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 50px;
    position: fixed;
}

#navbar {
    transition: all 0.2s ease-in-out, background-color 0.3s ease-in-out 0.2s, box-shadow 0.3s ease-in-out 0.2s;
    border-radius: 15px;
    padding: 0;
}


#navbar-wrapper.detached #navbar {
    position: fixed;
    left: 0;
    right: 0;
    max-width: 600px;
    /* background-color: white; */
    background-color: var(--primary);
    margin-top: 12px;
    padding: 4px 0px !important;
    box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.2);
}

#centernav {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
    #centernav {
        position: initial;
        left: 0;
        top: 0;
        transform: none;
    }

    #navbar-wrapper.detached #navbar {
        margin-top: 0;
    }

    #navbar {
        border-radius: 0 0 15px 15px;
        background-color: var(--primary);
    }
}

.logo-text {
    margin-bottom: 0;
    font-weight: 700;
    /* color: #fff; */
    transition-delay: 0.2s;
}

#navbar-wrapper.detached #navbar .logo-text {
    color: #000;
}

.navbar-toggler {
    border: none;
    background: none;
    color: #fff;
    font-size: 1rem;
}

.nav-item {
    border-radius: 10px;
}

.nav-item a {
    padding: 4px 10px;
    color: #000;
    font-weight: 500;
    letter-spacing: -0.3px;
    border-radius: 10px;
    transition: all 0.1s ease-in-out;
    display: flex;
    align-items: center;
    gap: 4px;
}

.nav-item a:hover {
    background: #fff;
    color: var(--primary);
    transform: scale(1.1);
}

.navbar-nav {
    gap: 8px;
}

.menu-gripper {
    font-size: 1.4rem;
    transition: all 0.2s ease-in-out;
}
</style>
