<template>
  <navigatie-balk />
  <router-view />
</template>

<script>
import NavigatieBalk from "@/components/utils/NavigatieBalk.vue";

export default {
  name: "App",
  components: {
    NavigatieBalk
  }
};
</script>
