import Axios from 'axios'

const getDefaultState = () => {
    return {
        amount: 0,
        donated_by_name: "",
        donated_by_email: "",
        donation_message: "",
        anonymous_donation: 1,
        keep_posted: 1,
        is_a_present: false,
        present_for_name: "",
        present_by_name: "",
        present_for_message: "",
        privacy_check: false,
    }
}

const donations = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        setValue(state, { key, value }) {
            state[key] = value;
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        setValue({ commit }, data) {
            commit('setValue', data)
        },

        async submitDonation({ state }) {
            if (!state.is_a_present) {
                state.present_for_name = "";
                state.present_by_name = "";
                state.present_for_message = "";
            }
            return await Axios.post('donate', state,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                .then(response => {
                    if (response.data.status == "success") {
                        // redirect to payment page
                        window.location.href = response.data.payment_url;
                    } else {
                        return (JSON.parse(response.data.message));
                    }
                })
        }
    }
}

export default donations