import Axios from 'axios'

const getDefaultState = () => {
  return {
    paidDonations: [],
  }
}

const donations = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        paidDonations: state => state.paidDonations,
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_PAID_DONATIONS(state, paidDonations) {
            state.paidDonations = paidDonations
        }
    },
    actions: {
        async fetchPaidDonations({ commit }) {
            try {
                const response = await Axios.get('getDonations')
                commit('SET_PAID_DONATIONS', response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export default donations