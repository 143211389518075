import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import donations from './stores/donations'
import MyDonation from './stores/MyDonation'

export default createStore({
  plugins: [createPersistedState()],
  modules: {
    donations,
    MyDonation
  }
})
