<template>
    <div>
        <div id="opties">
            <div v-for="optie in vasteOpties" :key="optie">
                <button @click="donate(optie)" class="btn btn-block btn-optie w-100">{{ optie }} m² </button>
            </div>
        </div>
        <input type="number" ref="input-optie" class='form-control input-theme' min="1" max="100000" step="0.01"
            :placeholder="$t('components.donatieStappen.anderBedrag')">
        <button class="btn btn-block btn-primary mt-2 py-3 w-100" @click="donate()">
            {{ $t('components.donatieStappen.doneer') }}
        </button>
    </div>
</template>

<script>
export default {
    name: "DonatieStappen",
    data() {
        return {
            vasteOpties: [5, 20, 50, 100]
        }
    },
    methods: {
        donate(amount = null) {
            if (amount === null) {
                amount = parseFloat(this.$refs['input-optie'].value)
            }
            this.$store.commit('MyDonation/setValue', {
                key: 'amount',
                value: amount
            })
            this.$router.push({ name: 'donate' })
        }
    }
}
</script>

<style scoped>
#opties {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.btn-optie {
    border-radius: 20px;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: var(--secondary-light);
    color: var(--secondary-dark);
}

.btn-optie:hover {
    background-color: var(--secondary-dark);
    color: var(--secondary-light);
}

.input-theme {
    text-align: center;
}
</style>