export default {
    algemeen: {
        naam: 'Minder zooi',
        logo: 'Minder<span class="text-white strong">Zooi.nl</span>'
    },
    paginas: {
        homepage: {
            title: 'Home',
            section1: {
                title: '€ 1 ruimt 1m² op',
                slug: "<span class='super-thick'>Jij</span> <br><span class='text-white'>ruimt Nederland op.</span>"
            },
            section2: {
                title: '3 stappen naar <span class="text-theme-primary">een schonere wereld</span> ',
                stappen: {
                    stap1: {
                        title: '1. Kies een hoeveelheid m²',
                        description: 'Die gaan wij ergens in Nederland opruimen'
                    },
                    stap2: {
                        title: '2. Vul je gegevens in',
                        description: 'Dit mag ook anoniem'
                    },
                    stap3: {
                        title: '3. Betaal en ruim op',
                        description: 'Betaal via iDeal, PayPal of Creditcard'
                    }
                }
            },
        },
        donatie: {
            amount_slug: 'Goed bezig!',
            amount_donating: 'Je gaat {amount} m² opruimen.',
            my_info: {
                name: 'Wat is jouw (bij)naam?',
                name_placeholder: 'Naam',
                email: 'E-mailadres',
                email_required: 'Jouw email wordt niet openbaar gemaakt.',
                message: 'Bericht',
                message_optional: 'Optioneel',
                anonymous: 'Anoniem doneren',
                keep_posted: 'Houd mij op de hoogte van de voortgang',
                is_it_a_present: 'Is het een cadeau?',
            },
            present_info: {
                about: 'Wil je in naam van iemand anders doneren? Jij ontvangt dan een e-mail met certificaat, die je kunt doorsturen naar de ontvanger.',
                to_name: 'Voor wie is deze donatie?',
                from_name: 'Namens wie is deze donatie?',
                name: 'Naam',
                message: 'Bericht'
            },
            payment: {
                title: 'Betaal met iDeal, PayPal of Creditcard',
                button: 'Betaal {amount} met {method}'
            },
            donate: 'Doneer',
            safePayment: 'Enjoycleaningup is een Erkend Goed Doel. Dat betekent dat wij voldoen aan strenge kwaliteitseisen. Toezichthouder CBF controleert dit. Meer weten? Kijk op geefgerust.nl',
            privacy_check: 'Ik ga akkoord met de',
            privacy_link: 'privacyvoorwaarden',
        }
    },
    components: {
        donatieStappen: {
            anderBedrag: 'Andere hoeveelheid',
            doneer: 'Ruim op!',
        }
    }
};